import React from "react";
import styles from "./Tooltip.module.scss";
import * as RadixTooltip from "@radix-ui/react-tooltip";

/**
 *
 * Tooltip
 *
 */
const Tooltip = ({ children, text = "", ...props }) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className="bg-black text-white p-2 rounded-md shadow-md z-[150]"
            sideOffset={5}
          >
            {text}
            <RadixTooltip.Arrow className={styles.arrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
