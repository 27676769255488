"use client";

import React, { useRef, useState } from "react";
import { X } from "lucide-react";
import { Transition } from "@/components/common/Transition";

import Link from "next/link";

const hospitalData = {
  "h-1": {
    title: "Restaurant/Store Entry",
    image:
      "https://embed.widencdn.net/img/masonite/pvbqcb3zw4/400px/Coffee%20exterior01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/products/stile-and-rail">
          <p>Aspiro&reg; Series – Stile {"&"} Rail</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>
            Stile &amp; Rail and Select Wood Veneer – for high design and
            natural appearance
          </li>
          <li>Multiple lite &amp; panel options</li>
          <li>
            Includes the latest trends in design, and offer the flexibility that
            enables you to shape the truly custom look that you are after
          </li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            sensitive
            <br />
            water based polyurethane
          </li>
          <li>
            WDMA Performance Level Extra Heavy Duty (where use is frequent)
          </li>
        </ul>
      </div>
    ),
  },
  "h-2": {
    title: "Bathroom",
    image:
      "https://embed.widencdn.net/img/masonite/obo2cikd7x/400px/Coffee%20interior01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/products/select-wood-veneer">
          <p>Aspiro&reg; Series – Wood Veneer</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>Non-rated</li>
          <li>Select Wood Veneer</li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            <br />
            sensitive water based polyurethane
          </li>
          <li>
            WDMA Performance Level Extra Heavy Duty (where use is frequent)
          </li>
        </ul>
      </div>
    ),
  },
  "h-3": {
    title: "Cross Corridor/Double Egress",
    image:
      "https://embed.widencdn.net/img/masonite/djw7qnh69u/400px/Lobby%20interior01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/categories/stile-and-rail">
          <p>Aspiro&reg; Series – Choice Laminates</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>90 minute Fire-rating</li>
          <li>
            High-Pressure Decorative Laminate Surface – dent and impact
            resistant to protect the door from
            <br />
            abuse
          </li>
          <li>
            Impact resistant edge – an integral 1-piece design, protecting the
            edge and
            <br />
            surface from heavy abuse
          </li>
          <li>
            WDMA Performance Level Extra Heavy Duty (where use is frequent
          </li>
        </ul>
      </div>
    ),
  },
  "h-4": {
    title: "Service / Back of House",
    image:
      "https://embed.widencdn.net/img/masonite/pdfxaj9szh/400px/Restaurant%20Interior01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/categories/choice-laminates">
          <p>Aspiro&reg; Series – Choice Laminates</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>
            High-Pressure Decorative Laminate Surface – dent and scratch
            resistant to protect the door from abuse
          </li>
          <li>
            High Impact Edge – an integral 1-piece design, protecting the edge
            and surface from
            <br />
            heavy abuse
          </li>
          <li>Top Clean Edge – for easy cleaning and optimal hygiene</li>
          <li>ANSI/WDMA Performance Level – extra heavy duty</li>
        </ul>
      </div>
    ),
  },
  "h-5": {
    title: "Office",
    image:
      "https://embed.widencdn.net/img/masonite/o5rumazifb/400px/Law%20office01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/products/select-wood-veneer">
          <p>Aspiro&reg; Series – Select Wood Veneer</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>Non-rated</li>
          <li>
            Select Wood Veneer with applied moulding – for high design and
            natural appearance
          </li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            sensitive water based
            <br />
            polyurethane
          </li>
          <li>
            Acoustic (STC) Rated – reduction in noise for productive work
            environments
          </li>
          <li>WDMA Performance Level Extra Heavy Duty</li>
        </ul>
      </div>
    ),
  },
  "h-6": {
    title: "Conference Rooms",
    image:
      "https://embed.widencdn.net/img/masonite/gop07xv7ug/400px/Arch%20conf%20rm01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/categories/select-wood-veneer">
          <p>Aspiro&reg; Series – Select Wood Veneer</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>Non-rated</li>
          <li>
            Select Wood Veneer with applied moulding – for high design and
            natural appearance
          </li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            sensitive water based
            <br />
            polyurethane
          </li>
          <li>
            Acoustic (STC) Rated – reduction in noise for productive work
            environments
          </li>
          <li>WDMA Performance Level Extra Heavy Duty</li>
        </ul>
      </div>
    ),
  },
  "h-7": {
    title: "Office Common Areas",
    image:
      "https://embed.widencdn.net/img/masonite/tonexk8u6t/400px/Shared%20work%20office01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/products/select-wood-veneer">
          <p>Aspiro&reg; Series – Select Wood Veneer</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>Non-rated</li>
          <li>
            Select Wood Veneer with applied moulding – for high design and
            natural appearance
          </li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            sensitive water based
            <br />
            polyurethane
          </li>
          <li>
            Acoustic (STC) Rated – reduction in noise for productive work
            environments
          </li>
          <li>WDMA Performance Level Extra Heavy Duty</li>
        </ul>
      </div>
    ),
  },
  "h-8": {
    title: "Office Bathrooms",
    image:
      "https://embed.widencdn.net/img/masonite/wxwpeephbo/400px/Arch%20bath01.jpeg?position=c&crop=no&color=ffffffff&quality=75&extension=.jpeg",
    description: (
      <div className="stack gap-5">
        <h3 className="font-bold">Recommended Door</h3>
        <Link className="text-gold" href="/products/select-wood-veneer">
          <p>Aspiro&reg; Series – Select Wood Veneer</p>
        </Link>

        <h3 className="font-bold">Performance Solutions</h3>
        <ul className="stack gap-4 list-disc list-outside ml-5">
          <li>Non-rated</li>
          <li>Select Wood Veneer</li>
          <li>
            Factory Finish – durable UV Finishes made from environmentally
            sensitive water based
            <br />
            polyurethane
          </li>
          <li>
            WDMA Performance Level Extra Heavy Duty (where use is frequent)
          </li>
        </ul>
      </div>
    ),
  },
};

export default function Office() {
  const [selected, setSelected] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const svgRef = useRef(null);

  const handleClick = (id, event) => {
    event.stopPropagation(); // Prevent the SVG click from immediately closing the popover
    const svgElement = svgRef.current;
    const clickedElement = event.target.closest("g");

    if (svgElement && clickedElement) {
      const svgRect = svgElement.getBoundingClientRect();
      const elementRect = clickedElement.getBoundingClientRect();

      const x = elementRect.left - svgRect.left + elementRect.width / 2;
      const y = elementRect.top - svgRect.top + 400;

      setPopoverPosition({ x, y });
      setSelected(id);
    }
  };

  const closePopover = () => {
    setSelected(null);
  };

  return (
    <section className="bg-white relative">
      <svg
        ref={svgRef}
        className="w-full"
        viewBox="0 0 1440 1085"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="1440" height="1091" fill="url(#pattern0_0_1)" />
        {selected && (
          <rect
            x="0"
            y="0"
            width="1440"
            height="1091"
            fill="black"
            opacity="0.5"
          />
        )}
        <g
          id="h-2"
          onClick={(e) => handleClick("h-2", e)}
          style={{ cursor: "pointer", transform: "translate(-620px, 20px)" }}
        >
          <circle
            cx="934.5"
            cy="637.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="935" cy="637" r="30" fill="white" />
          <path
            d="M934.36 620.88C942.328 620.88 947.176 625.104 947.176 630.864C947.176 636.432 943.624 639.12 938.536 641.328C932.968 643.872 932.008 644.976 931.816 646.512H948.088V654H920.824C920.776 653.472 920.728 652.8 920.728 652.128C920.728 645.216 922.888 641.088 930.04 637.392C934.552 635.088 937.528 633.504 937.528 630.768C937.528 629.04 936.376 627.888 934.456 627.888C932.104 627.888 930.664 629.424 930.616 632.4H921.16C921.208 625.008 926.056 620.88 934.36 620.88Z"
            fill="#B7974A"
          />
        </g>

        <g
          id="h-4"
          onClick={(e) => handleClick("h-4", e)}
          style={{ cursor: "pointer", transform: "translate(850px, 296px)" }}
        >
          <circle
            cx="233.5"
            cy="408.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="234" cy="408" r="30" fill="white" />
          <circle
            cx="233.5"
            cy="408.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="234" cy="408" r="30" fill="white" />
          <path
            d="M248.24 418.76H242.816V425H233.6V418.76H218.48V411.464L230.72 392.648H242.816V411.8H248.24V418.76ZM226.448 411.8H233.984V408.536C233.984 405.416 234.08 402.296 234.32 399.176H234.224C232.784 401.912 231.152 404.648 229.376 407.336L226.448 411.8Z"
            fill="#B7974A"
          />
        </g>
        <g
          id="h-1"
          onClick={(e) => handleClick("h-1", e)}
          style={{ cursor: "pointer", transform: "translate(-620px, 212px)" }}
        >
          <circle
            cx="845.5"
            cy="460.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />

          <circle cx="846" cy="460" r="30" fill="white" />
          <path
            d="M851.888 477H842.336V457.176H834.464V450.36C840.176 450.36 842.72 448.68 843.344 444.648H851.888V477Z"
            fill="#B7974A"
          />
        </g>
        <g
          id="h-6"
          onClick={(e) => handleClick("h-6", e)}
          style={{ cursor: "pointer", transform: "translate(430px, -101px)" }}
        >
          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M459.368 564.88C466.52 564.88 471.272 568.576 471.704 575.008H462.248C462.008 572.8 460.856 571.648 458.936 571.648C456.296 571.648 454.376 574 454.184 579.712H454.28C456.104 577.792 458.456 576.832 461.528 576.832C467.72 576.832 472.136 581.152 472.136 587.248C472.136 594.064 466.712 598.768 458.936 598.768C450.2 598.768 445.016 593.2 445.016 582.448C445.016 571.456 450.44 564.88 459.368 564.88ZM458.984 583.264C456.776 583.264 455.288 584.992 455.288 587.584C455.288 590.128 456.776 591.808 459.032 591.808C461.24 591.808 462.728 590.08 462.728 587.632C462.728 585.04 461.24 583.264 458.984 583.264Z"
            fill="#B7974A"
          />

          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="459" cy="581" r="30" fill="white" />
          <path
            d="M459.368 564.88C466.52 564.88 471.272 568.576 471.704 575.008H462.248C462.008 572.8 460.856 571.648 458.936 571.648C456.296 571.648 454.376 574 454.184 579.712H454.28C456.104 577.792 458.456 576.832 461.528 576.832C467.72 576.832 472.136 581.152 472.136 587.248C472.136 594.064 466.712 598.768 458.936 598.768C450.2 598.768 445.016 593.2 445.016 582.448C445.016 571.456 450.44 564.88 459.368 564.88ZM458.984 583.264C456.776 583.264 455.288 584.992 455.288 587.584C455.288 590.128 456.776 591.808 459.032 591.808C461.24 591.808 462.728 590.08 462.728 587.632C462.728 585.04 461.24 583.264 458.984 583.264Z"
            fill="#B7974A"
          />
        </g>
        <g
          id="h-5"
          onClick={(e) => handleClick("h-5", e)}
          style={{ cursor: "pointer", transform: "translate(630px, -71px)" }}
        >
          <circle
            cx="285.5"
            cy="667.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="286" cy="667" r="30" fill="white" />
          <path
            d="M296.88 659.136H282L281.472 664.56H281.568C283.392 663.024 285.6 662.256 288.096 662.256C294.48 662.256 298.944 666.624 298.944 672.864C298.944 680.112 293.52 684.768 285.264 684.768C277.248 684.768 272.064 680.976 272.064 674.496H281.616C281.664 676.608 283.2 677.904 285.408 677.904C287.952 677.904 289.488 676.368 289.488 673.344C289.488 670.416 287.856 668.88 285.504 668.88C283.392 668.88 282.144 670.128 281.76 671.808L272.544 671.232L274.32 651.648H296.88V659.136Z"
            fill="#B7974A"
          />
        </g>
        <g
          id="h-7"
          onClick={(e) => handleClick("h-7", e)}
          style={{ cursor: "pointer", transform: "translate(160px, -171px)" }}
        >
          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M459.368 564.88C466.52 564.88 471.272 568.576 471.704 575.008H462.248C462.008 572.8 460.856 571.648 458.936 571.648C456.296 571.648 454.376 574 454.184 579.712H454.28C456.104 577.792 458.456 576.832 461.528 576.832C467.72 576.832 472.136 581.152 472.136 587.248C472.136 594.064 466.712 598.768 458.936 598.768C450.2 598.768 445.016 593.2 445.016 582.448C445.016 571.456 450.44 564.88 459.368 564.88ZM458.984 583.264C456.776 583.264 455.288 584.992 455.288 587.584C455.288 590.128 456.776 591.808 459.032 591.808C461.24 591.808 462.728 590.08 462.728 587.632C462.728 585.04 461.24 583.264 458.984 583.264Z"
            fill="#B7974A"
          />

          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="459" cy="581" r="30" fill="white" />
          <path
            cx="459"
            cy="581"
            d="M26.32 0.647999V7.992C21.472 14.664 17.92 23.832 16.192 33H6.016C8.128 23.208 12.448 14.04 17.008 8.424H0.112V0.647999H26.32Z"
            fill="#B7974A"
            style={{ transform: "translate(446px, 566px)" }}
          />
        </g>
        <g
          id="h-8"
          onClick={(e) => handleClick("h-8", e)}
          style={{ cursor: "pointer", transform: "translate(600px, -93px)" }}
        >
          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <path
            d="M459.368 564.88C466.52 564.88 471.272 568.576 471.704 575.008H462.248C462.008 572.8 460.856 571.648 458.936 571.648C456.296 571.648 454.376 574 454.184 579.712H454.28C456.104 577.792 458.456 576.832 461.528 576.832C467.72 576.832 472.136 581.152 472.136 587.248C472.136 594.064 466.712 598.768 458.936 598.768C450.2 598.768 445.016 593.2 445.016 582.448C445.016 571.456 450.44 564.88 459.368 564.88ZM458.984 583.264C456.776 583.264 455.288 584.992 455.288 587.584C455.288 590.128 456.776 591.808 459.032 591.808C461.24 591.808 462.728 590.08 462.728 587.632C462.728 585.04 461.24 583.264 458.984 583.264Z"
            fill="#B7974A"
          />

          <circle
            cx="458.5"
            cy="581.5"
            r="35.5"
            stroke="white"
            strokeWidth="2"
          />
          <circle cx="459" cy="581" r="30" fill="white" />

          <path
            d="M14.504 0.879997C22.184 0.879997 27.224 4.528 27.224 10C27.224 13.504 25.016 16.24 21.272 17.152V17.248C25.304 18.112 27.992 21.136 27.992 25.168C27.992 31.216 22.952 34.768 14.504 34.768C6.008 34.768 0.968 31.216 0.968 25.168C0.968 21.136 3.656 18.112 7.688 17.248V17.152C3.944 16.24 1.736 13.504 1.736 10C1.736 4.528 6.824 0.879997 14.504 0.879997ZM14.504 7.6C12.104 7.6 10.568 8.896 10.568 10.912C10.568 12.88 12.104 14.224 14.504 14.224C16.904 14.224 18.392 12.88 18.392 10.912C18.392 8.896 16.856 7.6 14.504 7.6ZM14.504 20.848C11.96 20.848 10.376 22.192 10.376 24.352C10.376 26.512 11.96 27.952 14.504 27.952C17 27.952 18.584 26.512 18.584 24.352C18.584 22.192 17 20.848 14.504 20.848Z"
            fill="#B7974A"
            style={{ transform: "translate(444px, 564px)" }}
          />
        </g>
        <g
          id="h-3"
          onClick={(e) => handleClick("h-3", e)}
          style={{ cursor: "pointer", transform: "translate(520px, -206px)" }}
        >
          <circle
            cx="212.5"
            cy="904.5"
            r="35.5"
            stroke="white"
            stroke-width="2"
          ></circle>
          <circle cx="213" cy="904" r="30" fill="white"></circle>
          <path
            d="M212.264 887.88C220.328 887.88 225.224 891.48 225.224 897.048C225.224 900.6 223.592 902.712 219.656 904.152V904.248C223.832 905.592 225.656 907.944 225.656 911.832C225.656 917.928 220.472 921.768 212.408 921.768C203.336 921.768 198.344 917.64 198.344 910.056H207.944C207.944 913.176 209.528 914.76 212.36 914.76C214.712 914.76 216.152 913.512 216.152 911.592C216.152 909.288 214.568 908.136 211.016 908.136H210.2V901.32H211.304C214.136 901.32 215.864 900.024 215.864 898.008C215.864 896.136 214.568 894.888 212.264 894.888C209.528 894.888 208.136 896.52 208.136 899.352H198.824C198.824 892.152 203.816 887.88 212.264 887.88Z"
            fill="#B7974A"
          ></path>
        </g>

        <defs>
          <pattern
            id="pattern0_0_1"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_0_1"
              transform="scale(0.000694444 0.00091659)"
            />
          </pattern>
          <pattern
            id="pattern1_0_1"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_0_1"
              transform="scale(0.000694444 0.00091659)"
            />
          </pattern>
          <image
            id="image0_0_1"
            width="1440"
            height="1091"
            xlinkHref="https://embed.widencdn.net/img/masonite/zmg89qbeoa/exact/office-building-hero-1440.jpeg"
          />
        </defs>
      </svg>

      {selected && (
        <Transition
          isVisible={!!selected}
          className="absolute bg-white p-8 shadow-lg w-[500px] max-w-full z-10"
          style={{
            left: `${popoverPosition.x}px`,
            top: `${popoverPosition.y}px`,
            transform: "translate(-50%, -100%)",
            marginTop: "-10px",
            zIndex: 10,
          }}
        >
          <button
            onClick={closePopover}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
          <h2 className="text-4xl font-bold mb-2">
            {hospitalData[selected].title}
          </h2>
          <img
            src={hospitalData[selected].image}
            alt={hospitalData[selected].title}
            className="w-full object-cover mb-4 aspect-[1.5/1]"
          />
          <div className="text-sm text-gray-500">
            {hospitalData[selected].description}
          </div>
        </Transition>
      )}
    </section>
  );
}
