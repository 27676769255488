"use client";

import React, { useState } from "react";
import styles from "./Accordion.module.scss";
import * as Accordion from "@radix-ui/react-accordion";
import { Plus } from "lucide-react";
import If from "@/components/common/If";

import Link from "next/link";
import RichText from "@/components/common/RichText";

/**
 *
 * Details
 *
 */
const Details = ({ items, ...props }) => {
  console.log("Here", items);
  const [currentOpen, setCurrentOpen] = useState(null);
  return (
    <section className={styles.details} {...props}>
      <Accordion.Root
        className="w-full"
        collapsible
        onValueChange={(value) => {
          setCurrentOpen(value);
        }}
      >
        {items.map((item, index) => (
          <Accordion.Item
            key={item.title}
            value={item.title}
            className="border-b border-b-black pt-4 pb-3 w-full block"
          >
            <Accordion.Header>
              <Accordion.Trigger
                className={`stack gap-2 w-full pb-2 ${styles.trigger} text-left`}
              >
                <div className="flex justify-between items-center w-full">
                  <If condition={item.link}>
                    <Link
                      href={item.link}
                      target={item.external ? "_blank" : "_self"}
                    >
                      <h2 className="text-[1.5rem] font-hero text-current">
                        {item.title}
                      </h2>
                    </Link>
                    <svg
                      className="mr-3"
                      width="20"
                      viewBox="0 0 27 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1.25"
                        y1="0.566987"
                        x2="26.5116"
                        y2="15.1518"
                        stroke="#EAE8E4"
                      />
                      <line
                        x1="0.832031"
                        y1="29.567"
                        x2="26.0936"
                        y2="14.9822"
                        stroke="#EAE8E4"
                      />
                    </svg>
                  </If>
                  <h2 className="text-[1.5rem] font-hero text-current">
                    {item.title}
                  </h2>
                  <Plus
                    className={`transtion-all ease-in-out duration-300 ${
                      currentOpen === item.title ? "rotate-45" : ""
                    } relative block`}
                    size={38}
                    color="black"
                    strokeWidth={0.5}
                  />
                </div>
                <If condition={item.detail}>
                  <p className="text-sm font-thin text-white">{item.detail}</p>
                </If>
              </Accordion.Trigger>
            </Accordion.Header>
            <If condition={!item.link}>
              <Accordion.Content
                className={`py-10 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden ${styles.content}`}
              >
                {item.description || item.children}
                <If condition={item.content?.json}>
                  <RichText json={item.content?.json} />
                </If>
              </Accordion.Content>
            </If>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  );
};

export default Details;
