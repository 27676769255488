"use client";

import useIntersectionObserver from "@/hooks/useIntersection";
import { useEffect, useRef } from "react";

interface AnimateInProps {
  children?: React.ReactNode;
  className?: string;
  delay?: string;
  as?: string;
  threshold?: number;
  isInterior?: boolean;
}

export default function AnimateIn({
  children = null,
  className = "",
  delay = "",
  as = "div",
  threshold = 0.01,
  isInterior = false,
  ...props
}: AnimateInProps) {
  const Component = as || "div";

  const { observerRef } = useIntersectionObserver({
    onIntersect: (target) => {
      target.classList.add("animate-up");
    },
    onNotIntersect: (target) => {
      target.classList.remove("animate-up");
    },
  });

  return (
    <Component
      className={`will-animate-up ${delay} ${className}`}
      ref={observerRef}
      {...props}
    >
      {children}
    </Component>
  );
}
