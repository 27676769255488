import styles from "./Button.module.scss";
import classNames from "clsx";

import Link from "next/link";

interface ButtonProps {
  size?: "small" | "medium" | "large";
  variant?: "primary";
  isLight?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  ariaLabel?: string;
  type?: "button" | "submit" | "reset";
  href?: string;
}

const Button: React.FC<ButtonProps> = ({
  size = "medium",
  variant = "primary",
  isLight = false,
  disabled = false,
  type = "button",
  href = "",
  onClick,
  children,
  iconLeft,
  iconRight = true,
  ariaLabel,
}) => {
  const buttonClasses = classNames(
    styles.btn,
    styles[`btn-${variant}`],
    styles[`btn-${size}`],
    {
      [styles.disabled]: disabled,
    },
    isLight && styles.light,
  );

  if (href && href !== "#") {
    return (
      <Link href={href} className={buttonClasses} aria-label={ariaLabel}>
        {iconLeft && (
          <span className={styles["btn-icon-left"]}>{iconLeft}</span>
        )}
        <span>{children}</span>
        {iconRight && (
          <span className={styles["btn-icon-right"]}>
            <svg
              width="23"
              height="25"
              viewBox="0 0 23 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_759)">
                <path
                  d="M23 12.4341L10.6393 0L9.18273 1.74804L19.0795 11.225H0L0.0267811 13.6675H19.0795L9.15595 23.1769L10.6125 24.925L22.9732 12.4884L22.965 12.4786L23 12.4341Z"
                  fill="#B7974A"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_759">
                  <rect width="23" height="24.925" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        )}
      </Link>
    );
  }

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled || href === "#"}
      aria-label={ariaLabel}
      type={type}
    >
      {iconLeft && <span className={styles["btn-icon-left"]}>{iconLeft}</span>}
      <span>{children}</span>
      {iconRight && (
        <span className={styles["btn-icon-right"]}>
          <svg
            width="23"
            height="25"
            viewBox="0 0 23 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_759)">
              <path
                d="M23 12.4341L10.6393 0L9.18273 1.74804L19.0795 11.225H0L0.0267811 13.6675H19.0795L9.15595 23.1769L10.6125 24.925L22.9732 12.4884L22.965 12.4786L23 12.4341Z"
                fill="#B7974A"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_759">
                <rect width="23" height="24.925" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      )}
    </button>
  );
};

export default Button;
