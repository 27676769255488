import React from "react";
import styles from "./CTAs.module.scss";
import For from "@/components/common/For";
import If from "@/components/common/If";
import { Button } from "@/components/actions/Button";
import AnimateIn from "@/components/common/AnimateIn";
import { FileDown } from "lucide-react";

/**
 *
 * CtAs
 *
 */
const CTAs = ({
  ctas = [],
  align = "center",
  isLight = false,
  ...props
}: any) => {
  return (
    <div
      className={`stack gap-4 ctas ${align === "center" ? "justify-center items-center" : ""}`}
    >
      <For
        each={ctas}
        render={(cta, index) => {
          return (
            <AnimateIn
              as="div"
              delay={`delay-${index}`}
              className="w-max  max-w-full"
              key={cta?.sys?.id}
            >
              <If condition={cta.type === "Download"}>
                <a
                  href={cta.link}
                  className="w-max max-w-full flex gap-2 items-center text-2xl"
                  target="_blank"
                >
                  <FileDown color="#808080" size={30} />
                  <span className="text-[1.25rem]">{cta.title}</span>
                </a>
              </If>
              <If condition={cta.type !== "Download"}>
                <If condition={cta.title && cta.link}>
                  <Button
                    href={cta.link}
                    className="w-max max-w-full"
                    isLight={isLight}
                  >
                    {cta.title}
                  </Button>
                </If>
                <If condition={cta.title && cta.page?.handle}>
                  <Button
                    href={`/${cta.page?.handle}`}
                    className="w-max max-w-full"
                    isLight={isLight}
                  >
                    {cta.title}
                  </Button>
                </If>
              </If>
            </AnimateIn>
          );
        }}
      />
    </div>
  );
};

export default CTAs;
