import { BLOCKS } from "@contentful/rich-text-types";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  AwaitedReactNode,
} from "react";

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (
      node: any,
      children:
        | string
        | number
        | bigint
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | Promise<AwaitedReactNode>
        | null
        | undefined,
    ) => <p>{children}</p>,
  },
  renderText: (text: string) =>
    text.split("\n").flatMap((text: any, i: number) => [i > 0 && <br />, text]),
};
