"use client";

import { useEffect, useRef, useState } from "react";
import If from "./If";

export default function Image({
  src = "",
  alt = "",
  maxWidth = "",
  shouldLazyLoad = true,
  objectPosition = "center",
  cover = true,
  width = "",
  height = "",
  className = "",
  imgClassName = "",
  fillContainer = false,
  imgStyle = {},
  ...props
}) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const img = imgRef.current;

    if (!img) return;

    const handleLoad = () => {
      setLoaded(true);
    };

    if (img.complete) {
      handleLoad();
    } else {
      img.addEventListener("load", handleLoad);
    }

    return () => {
      img.removeEventListener("load", handleLoad);
    };
  }, [src]);

  const commonImgProps = {
    ref: imgRef,
    width: width,
    height: height,
    alt: alt,
    loading: shouldLazyLoad ? "lazy" : "eager",
    decoding: "async",
    fetchPriority: shouldLazyLoad ? "low" : "high",
    className: `${objectPosition === "center" ? "object-center" : ""}
      ${cover ? "object-cover" : "object-contain"}
      ${imgClassName}
      ${fillContainer ? "h-full w-full" : ""}
      transition-opacity duration-300 ease-in
      ${loaded ? "opacity-100" : "opacity-0"}`,
    style: imgStyle,
  };

  const renderImage = (srcWithParams) => (
    <img {...commonImgProps} src={srcWithParams} />
  );

  return (
    <picture
      className={`${className} ${fillContainer ? "h-full w-full" : ""}`}
      {...props}
    >
      <If condition={!src?.includes("bfldr.com")}>
        <source
          type="image/avif"
          media="(max-width: 500px)"
          srcSet={`${src}?fm=avif&w=1000`}
        />
        <source
          type="image/avif"
          media="(max-width: 1200px)"
          srcSet={`${src}?fm=avif&w=1200`}
        />
        <source
          type="image/avif"
          media="(min-width: 1201px)"
          srcSet={`${src}?fm=avif${maxWidth ? `&w=${maxWidth}` : ""}`}
        />
        <source
          type="image/webp"
          media="(max-width: 500px)"
          srcSet={`${src}?fm=webp&w=1000`}
        />
        <source
          type="image/webp"
          media="(max-width: 1200px)"
          srcSet={`${src}?fm=webp&w=1200`}
        />
        <source
          type="image/webp"
          media="(min-width: 1201px)"
          srcSet={`${src}?fm=webp${maxWidth ? `&w=${maxWidth}` : ""}`}
        />
        <source
          type="image/jpeg"
          media="(max-width: 500px)"
          srcSet={`${src}?fm=jpg&w=1000`}
        />
        <source
          type="image/jpeg"
          media="(max-width: 1200px)"
          srcSet={`${src}?fm=jpg&w=1200`}
        />
        <source
          type="image/jpeg"
          media="(min-width: 1201px)"
          srcSet={`${src}?fm=jpg${maxWidth ? `&w=${maxWidth}` : ""}`}
        />
        {renderImage(`${src}${maxWidth ? `?w=${maxWidth}` : ""}`)}
      </If>

      <If condition={src?.includes("bfldr.com")}>
        <source
          type="image/webp"
          media="(max-width: 500px)"
          srcSet={`${src}&width=1000`}
        />
        <source
          type="image/webp"
          media="(max-width: 1200px)"
          srcSet={`${src}&width=1200`}
        />
        <source
          type="image/webp"
          media="(min-width: 1201px)"
          srcSet={`${src}${maxWidth ? `&width=${maxWidth}` : ""}`}
        />
        <source
          type="image/jpeg"
          media="(max-width: 500px)"
          srcSet={`${src}&width=1000`}
        />
        <source
          type="image/jpeg"
          media="(max-width: 1200px)"
          srcSet={`${src}&width=1200`}
        />
        <source
          type="image/jpeg"
          media="(min-width: 1201px)"
          srcSet={`${src}${maxWidth ? `&width=${maxWidth}` : ""}`}
        />
        {renderImage(`${src}${maxWidth ? `&width=${maxWidth}` : ""}`)}
      </If>
    </picture>
  );
}
