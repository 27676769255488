import If from "@/components/common/If";
import {
  AudioLines,
  HammerIcon,
  Leaf,
  Lock,
  Music,
  ShieldCheck,
  ShowerHead,
} from "lucide-react";

import Link from "next/link";

import { Button } from "@/components/actions/Button";

import Image from "@/components/common/Image";
import { Tooltip } from "@/components/ui/Tooltip";
import RichText from "@/components/common/RichText";

interface CardProps {
  title: string;
  description: string;
  image?: string;
  className?: string;
  showButton?: boolean;
  href?: string;
  linkText?: string;
  showResources?: boolean;
  isLight?: boolean;
  imageCover?: boolean;
  showSolutions?: boolean;
  solutions?: any;
  cardType?: string;
  subtitle?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  image,
  className = "",
  showButton = true,
  showSolutions = true,
  href = "",
  linkText = "Learn More",
  showResources = true,
  isLight = false,
  imageCover = true,
  solutions = [],
  cardType = "Default",
  subtitle = "",
}) => {
  return (
    <article
      className={`flex-card ${!imageCover ? "px-[2rem]" : ""} ${className} stack`}
    >
      <If condition={image}>
        <Image
          src={image}
          alt={title}
          // className={`w-full object-cover h-[600px] aspect-[1/1] mix-blend-multiply`}
          imgClassName={`w-full ${cardType === "Icon" ? "h-[80px]" : "h-[600px] max-h-[50dvh]"} aspect-[1/1]`}
          cover={imageCover}
          maxWidth="1000"
        />
      </If>
      <If condition={!image}>
        <div className="bg-gray-200 aspect-[1/1] w-full mb-4"></div>
      </If>
      <div
        className={`py-4 stack gap-6 flex-1 justify-between ${!imageCover ? "" : "px-[2rem]"}`}
      >
        <div className="stack gap-6">
          <div className="stack gap-3">
            <If condition={showSolutions}>
              <div className="flex gap-4 opacity-40">
                <If condition={solutions?.includes("Durability")}>
                  <Link href="/solutions/durability">
                    <Tooltip text="Durability">
                      <HammerIcon size={24} />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Safety")}>
                  <Link href="/solutions/safety">
                    <Tooltip text="Safety">
                      <ShieldCheck size={24} />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Security")}>
                  <Link href="/solutions/security">
                    <Tooltip text="Security">
                      <Lock size={24} />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Acoustics")}>
                  <Link href="/solutions/acoustics">
                    <Tooltip text="Acoustics">
                      <AudioLines size={24} />
                    </Tooltip>
                  </Link>
                </If>
                <If condition={solutions?.includes("Cleanability")}>
                  <Link href="/solutions/cleanability">
                    <Tooltip text="Cleanability">
                      <ShowerHead size={24} />
                    </Tooltip>
                  </Link>
                </If>
              </div>
            </If>
            <If condition={subtitle}>
              <h3
                className="text-sm text-gray"
                dangerouslySetInnerHTML={{
                  __html: subtitle
                    ?.replace("Aspiro", "Aspiro<sup>&copy;</sup>")
                    ?.replace("Cendura", "Cendura<sup>&copy;</sup>"),
                }}
              />
            </If>
            <h2
              className={`text-[2rem] fon-normal mb-2 ${cardType === "Icon" ? "text-center" : "min-h-[2em]"}`}
            >
              {title}
            </h2>
            <If condition={cardType !== "Icon"}>
              <div className="bg-gold h-[5px] w-[35px]" />
            </If>
          </div>
          <If condition={description?.json}>
            <div
              className={`text-gray-600  overflow-hidden text-body ${cardType === "Icon" ? "text-center" : ""}`}
            >
              <RichText json={description?.json} />
            </div>
          </If>
        </div>
        <If condition={showButton && href}>
          <div className="flex stack gap-8">
            <Button href={href} isLight={isLight}>
              {linkText || "Learn More"}
            </Button>
          </div>
        </If>
      </div>
    </article>
  );
};

export default Card;
