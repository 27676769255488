"use client";

import React, { useState, useMemo } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const DynamicTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [stcFilter, setStcFilter] = useState("all");
  const [fireFilter, setFireFilter] = useState("all");
  const itemsPerPage = 10;

  const filteredAndSortedData = useMemo(() => {
    return data
      .filter(
        (item) =>
          Object.values(item).some((value) =>
            value
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase()),
          ) &&
          (stcFilter === "all" || item["STC Rating"] === parseInt(stcFilter)) &&
          (fireFilter === "all" ||
            item["Fire rating"] === parseInt(fireFilter)),
      )
      .sort((a, b) => {
        if (sortColumn === "") return 0;
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
        }
        return sortDirection === "asc"
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      });
  }, [data, searchTerm, sortColumn, sortDirection, stcFilter, fireFilter]);

  const pageCount = Math.ceil(filteredAndSortedData.length / itemsPerPage);
  const paginatedData = filteredAndSortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return (
    <div className="space-y-4">
      <Input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full"
      />
      <div className="flex space-x-4">
        <Select value={stcFilter} onValueChange={setStcFilter}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter STC Rating" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="all">All STC Ratings</SelectItem>
            {[...new Set(data.map((item) => item["STC Rating"]))]
              .sort((a, b) => a - b)
              .map((rating) => (
                <SelectItem key={rating} value={rating.toString()}>
                  {rating}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        <Select value={fireFilter} onValueChange={setFireFilter}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter Fire Rating" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="all">All Fire Ratings</SelectItem>
            {[...new Set(data.map((item) => item["Fire rating"]))]
              .sort((a, b) => a - b)
              .map((rating) => (
                <SelectItem key={rating} value={rating.toString()}>
                  {rating}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {Object.keys(data[0]).map((column) => (
              <TableHead
                key={column}
                onClick={() => handleSort(column)}
                className="cursor-pointer"
              >
                {column}
                {sortColumn === column && (
                  <span className="ml-1">
                    {sortDirection === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedData.map((item, index) => (
            <TableRow key={index}>
              {Object.values(item).map((value, valueIndex) => (
                <TableCell key={valueIndex}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-between items-center">
        <div>
          Page {currentPage} of {pageCount}
        </div>
        <div className="space-x-2">
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, pageCount))
            }
            disabled={currentPage === pageCount}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
