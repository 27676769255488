"use client";

import useIntersectionObserver from "@/hooks/useIntersection";
import { useEffect, useRef } from "react";

interface AnimateInProps {
  children?: React.ReactNode;
  className?: string;
  delay?: string;
  as?: string;
  threshold?: number;
  isInterior?: boolean;
  title?: string;
}

export default function AnimateTitle({
  title = "",
  className = "",
  delay = "",
  as = "div",
  threshold = 0.01,
  isInterior = false,
  ...props
}: AnimateInProps) {
  const Component = as || "div";

  const { observerRef } = useIntersectionObserver({
    onIntersect: (target) => {
      target.classList.add("word-animate-up");
    },
    onNotIntersect: (target) => {
      target.classList.remove("word-animate-up");
    },
  });

  return (
    <Component
      className={`word-will-animate-up flex gap-[0.25em] leading-[0.8] pt-1 flex-wrap hide-text  ${delay} ${className}`}
      ref={observerRef}
      {...props}
    >
      {title.split(" ").map((word, index) => (
        <span className="word" key={index}>
          {word}
        </span>
      ))}
    </Component>
  );
}
