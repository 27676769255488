"use client";

import React, { useRef, useLayoutEffect, useMemo } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Parallax = ({
  children,
  speed = 1,
  start = "top bottom",
  end = "bottom top",
  axis = "y",
  ease = "none",
  className = "",
}) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const scrollTriggerConfig = useMemo(
    () => ({
      trigger: containerRef.current,
      start,
      end,
      scrub: true,
    }),
    [start, end],
  );

  useLayoutEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    const animation = gsap.fromTo(
      content,
      { [axis]: 0 },
      {
        [axis]: () =>
          container[axis === "y" ? "offsetHeight" : "offsetWidth"] * speed * -1,
        ease,
        scrollTrigger: scrollTriggerConfig,
      },
    );

    return () => {
      animation.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [speed, axis, ease, scrollTriggerConfig]);

  return (
    <div ref={containerRef} className={`parallax-container ${className}`}>
      <div ref={contentRef} className="parallax-content">
        {children}
      </div>
    </div>
  );
};

export default React.memo(Parallax);
